@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
      @apply text-3xl;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body, .MuiTab-root {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Seravek',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: hsl(235, 10%, 97%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
    position: absolute;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
}

.MuiTab-root>*, .MuiTypography-body1 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: normal;
}

.MuiTab-textColorSecondary {
  color: #909090;
}

.MuiTab-textColorSecondary.Mui-selected {
  color: black;
}
